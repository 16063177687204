<template>
  <div class="dropdown is-right is-hoverable">
    <div class="dropdown-trigger">
      <button class="button" aria-haspopup="true" aria-controls="report-menu">
        <span>
          {{ $gettext("Reports") }}
        </span>
        <span class="icon is-small">
          <IconCollapsed />
        </span>
      </button>
    </div>
    <div class="dropdown-menu" id="report-menu" role="menu">
      <div class="dropdown-content">
        <div class="dropdown-item">
          <router-link
            :to="{ name: `${urlNamePrefix}DemographicReport`, params }"
            :class="['dropdown-item', selected == 'DemographicReport' && 'is-active']"
          >
            {{ $gettext("Demographic Report") }}
          </router-link>
          <router-link
            :to="{ name: `${urlNamePrefix}MalnutritionReport`, params }"
            :class="['dropdown-item', selected == 'MalnutritionReport' && 'is-active']"
          >
            {{ $gettext("Malnutrition Report") }}
          </router-link>
          <router-link
            :to="{ name: `${urlNamePrefix}BestPracticesReport`, params }"
            :class="['dropdown-item', selected == 'BestPracticesReport' && 'is-active']"
          >
            {{ $gettext("Best Practices Report") }}
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
  import IconCollapsed from "@/components/svg/IconCollapsed.vue"

  const { selected, params } = defineProps({
    selected: {
      type: String,
      required: true,
    },
    // url params for all of the report links. (Specifying site, project, or (by omission) app levels.)
    params: {
      type: Object,
      default: {},
    }
  })

  let urlNamePrefix = "AppLevel"
  if (params.projectCmiId) {
    urlNamePrefix = "ProjectLevel"
  }
  else if (params.siteCmiId) {
    urlNamePrefix = "SiteLevel"
  }
</script>
