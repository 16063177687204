<template>
  <div class="mt-3">
    <ExpandCollapse
      :extra-toggle-function="extraToggleFunction"
      :expanded-at-start="expandedAtStart"
    >
      <template #label>
        <h2 class="subtitle">
          {{ project.name }}
        </h2>
      </template>
      <template #content>
        <div
          v-for="site in project.sites"
          :key="site.id"
          class="block"
        >
          <strong>{{ site.name }}</strong>
          <DownloadSiteContentWidget
            :site="site"
            :task-queuer="taskQueuer"
            :get-counts="getCounts"
          />
          <DownloadSiteContentWidget
            :site="site"
            :task-queuer="taskQueuer"
            :get-counts="getCounts"
            :for-discharged-children="true"
          />
          <DownloadSiteVisitReportsWidget
            v-if="site.canCreateSiteVisitReports"
            :site="site"
            :task-queuer="taskQueuer"
            :get-counts="getCounts"
          />
        </div>
      </template>
    </ExpandCollapse>
  </div>
</template>
<script lang="ts" setup>
  import { ref } from "vue"

  import DownloadSiteContentWidget from "@/components/sites/DownloadSiteContentWidget.vue"
  import DownloadSiteVisitReportsWidget from "@/components/sites/DownloadSiteVisitReportsWidget.vue"
  import ExpandCollapse from "@/components/ExpandCollapse.vue"

  import { ISite } from "@/db"
  import { TaskQueuer } from "@/utils/Utilities"

  interface Props {
    project: { sites: Array<ISite>, name: string }
    taskQueuer: TaskQueuer,
    getCountsPreemptively: boolean
    expandedAtStart: boolean
  }

  const {
    project,
    taskQueuer,
    getCountsPreemptively,
    expandedAtStart,
  } = defineProps<Props>()

  const getCounts = ref(Boolean(getCountsPreemptively))

  // Function called in the ExpandCollapse; lets widgets know that, if they
  // haven't already, they should get assessment counts for their site.
  const extraToggleFunction = () => getCounts.value = true
  </script>
<style scoped>
  h2.subtitle {
    margin-bottom: 0.5rem;
    text-transform: none;
    font-weight: bold;
  }
</style>
