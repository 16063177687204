<template>
  <footer class="has-background-primary">
    <div class="first footer-item">
      <router-link
        class="captioned-icon"
        :to="{ name: 'SiteList' }"
      >
        <IconHome />
        <span class="icon-caption">
          {{ $gettext("My Sites") }}
        </span>
      </router-link>
    </div>

    <div class="footer-item">
      <router-link
        class="captioned-icon"
        :to="{ name: 'ResourceLibrary' }"
      >
        <IconBook />
        <span class="icon-caption">
          {{ $gettext("Resource Library") }}
        </span>
      </router-link>
    </div>

    <div class="footer-item">
      <router-link
        class="captioned-icon"
        :to="{ name: 'PrepareForOffline' }"
      >
        <IconDownload />
        <span class="icon-caption">
          {{ $gettext("Prepare for Offline Usage") }}
        </span>
      </router-link>
    </div>
    <div
      id="pending-upload-count"
      class="footer-item"
    >
      <router-link
        :to="{ name: 'PendingUploads' }"
        class="captioned-icon"
      >
        <IconCheck v-if="!uploadStatusStore.pendingUploadCount" />
        <div
          v-else
          class="count"
        >
          {{ uploadStatusStore.pendingUploadCount }}
        </div>
        <span class="icon-caption">
          <template v-if="uploadStatusStore.isCurrentlySyncing">
            {{ $gettext("Syncing...") }}
          </template>
          <template v-else>
            {{ $gettext("Pending") }}
          </template>
        </span>
      </router-link>
    </div>
  </footer>
  <div style="color: #79A7C7; font-size: 50%; position: absolute; bottom: 0; left: 50%;">
    {{ CONFIG.COMMIT_SHA }}
  </div>
</template>

<script lang="ts" setup>
  import { onMounted, onUnmounted } from "vue"

  import IconBook from "@/components/svg/IconBook.vue"
  import IconHome from "@/components/svg/IconHome.vue"
  import IconDownload from "@/components/svg/IconDownload.vue"
  import IconCheck from "@/components/svg/IconCheckCircled.vue"

  import { useUploadStatusStore } from "@/utils/GlobalState"
  import { CONFIG } from "@/config"
  import { handlePendingUploads } from "@/services/Upload"

  const uploadStatusStore = useUploadStatusStore()

  onMounted(() => {
      window.addEventListener("online", handlePendingUploads)
    }
  )

  onUnmounted(() => {
    window.addEventListener("online", handlePendingUploads)
  })
</script>
