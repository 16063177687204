<script setup>
  import { setCurrentViewContext } from "@/utils/GlobalState"

  setCurrentViewContext(null, "library")
</script>
<template>
  <article>
    <h1 class="title">
      {{ $gettext("Resource Library") }}
    </h1>
    <div class="grid is-col-min-12 is-gap-2">
      <section class="cell">
        <h2 class="subtitle">
          {{ $gettext("Growth") }}
        </h2>
        <ul>
          <li>
            {{ $gettext("How to measure weight") }}
            <ul>
              <li>
                <router-link
                  :to="{ name: 'MeasureWeightInfant' }"
                  class="text-link"
                >
                  {{ $gettext("Infants") }}
                </router-link>
              </li>
              <li>
                <router-link
                  :to="{ name: 'MeasureWeightAdultChild' }"
                  class="text-link"
                >
                  {{ $gettext("Older children who cannot stand") }}
                </router-link>
              </li>
              <li>
                <router-link
                  :to="{ name: 'MeasureWeightChild' }"
                  class="text-link"
                >
                  {{ $gettext("Older children who are able to stand") }}
                </router-link>
              </li>
            </ul>
          </li>
          <li>
            <router-link
              :to="{ name: 'MeasureLength' }"
              class="text-link"
            >
              {{ $gettext("How to measure length") }}
            </router-link>
          </li>
          <li>
            <router-link
              :to="{ name: 'MeasureHeight' }"
              class="text-link"
            >
              {{ $gettext("How to measure height") }}
            </router-link>
          </li>
          <li>
            <router-link
              :to="{ name: 'MeasureHeadSize' }"
              class="text-link"
            >
              {{ $gettext("How to measure head size") }}
            </router-link>
          </li>
          <li>
            <router-link
              :to="{ name: 'MeasureArmSize' }"
              class="text-link"
            >
              {{ $gettext("How to measure mid-upper arm circumference") }}
            </router-link>
          </li>
        </ul>
      </section>
      <section class="cell">
        <h2 class="subtitle">
          {{ $gettext("Feeding") }}
        </h2>
        <ul>
          <li>
            <router-link
              :to="{ name: 'GuideToFoodTextures' }"
              class="text-link"
            >
              {{ $gettext("Guide to Food Textures") }}
            </router-link>
          </li>
          <li>
            <router-link
              :to="{ name: 'EmptySpoonTechnique' }"
              class="text-link"
            >
              {{ $gettext("\"Empty Spoon\" Technique") }}
            </router-link>
          </li>
          <li>
            <router-link
              :to="{ name: 'LateralSpoonTechnique' }"
              class="text-link"
            >
              {{ $gettext("\"Lateral Spoon\" Technique") }}
            </router-link>
          </li>
        </ul>
      </section>
      <section class="cell">
        <h2 class="subtitle">
          {{ $gettext("Breast Milk and Alternatives") }}
        </h2>
        <ul>
          <li>
            <router-link
              :to="{ name: 'BreastMilkAlternatives' }"
              class="text-link"
            >
              {{ $gettext("Choosing a breast milk alternative") }}
            </router-link>
          </li>
          <li>
            <router-link
              :to="{ name: 'BreastMilkExpression' }"
              class="text-link"
            >
              {{ $gettext("Breast milk expression and storage") }}
            </router-link>
          </li>
          <li>
            <router-link
              :to="{ name: 'InfantFormula' }"
              class="text-link"
            >
              {{ $gettext("Commercial infant formula") }}
            </router-link>
          </li>
          <li>
            <router-link
              :to="{ name: 'ModifiedAnimalMilk' }"
              class="text-link"
            >
              {{ $gettext("Home-modified animal milk for young infants") }}
            </router-link>
          </li>
          <li>
            <router-link
              :to="{ name: 'PasteurizeMilk' }"
              class="text-link"
            >
              {{ $gettext("Animal milk for older infants") }}
            </router-link>
          </li>
        </ul>
      </section>
      <section class="cell">
        <h2 class="subtitle">
          {{ $gettext("Anemia") }}
        </h2>
        <ul>
          <li>
            <router-link
              :to="{ name: 'MeasureHemoglobin' }"
              class="text-link"
            >
              {{ $gettext("How to measure hemoglobin with a Hemocue") }}
            </router-link>
          </li>
        </ul>
      </section>
    </div>
  </article>
</template>
<style scoped>
  ul {
    list-style: circle;
  }
  li {
    margin-left: 1em;
  }
  .coming-soon {
    color: gray;
  }
  h2.subtitle {
    margin-bottom: 0.25em;
  }
  .cell {
    padding-bottom: 1em;
  }
</style>
