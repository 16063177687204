<template>
  <div :class="['expand-collapse', extraClass]">
    <div
      class="expander-toggle"
      @click="toggle"
    >
      <slot name="label" />
      <template v-if="!permanentlyExpanded">
        <IconExpanded v-if="expanded" />
        <IconCollapsed v-else />
      </template>
    </div>
    <transition
      name="expander"
      @before-enter="beforeExpand"
      @enter="expand"
      @before-leave="beforeCollapse"
      @leave="collapse"
    >
      <div
        v-show="expanded || permanentlyExpanded"
        class="expander-content-wrapper"
      >
        <div class="expander-content">
          <slot name="content" />
        </div>
      </div>
    </transition>
  </div>
</template>

<script lang="ts" setup>
  // This approach borrowed from https://codepen.io/ktsn/pen/dzNRjx
  import { ref } from "vue"

  import IconCollapsed from "@/components/svg/IconCollapsed.vue"
  import IconExpanded from "@/components/svg/IconExpanded.vue"

  const props = defineProps({
    expandedAtStart: {
      type: Boolean,
      default: false,
    },
    extraClass: {
      type: String,
      default: ""
    },
    permanentlyExpanded: {
      type: Boolean,
      default: false,
    },
    // Hook for additional logic to be called upon each toggle.
    // Assumes no args and ignores return values.
    extraToggleFunction: {
      type: Function,
      default: () => {},
    }
  })

  const expanded = ref(props.expandedAtStart)

  function toggle() {
    props.extraToggleFunction()
    expanded.value = !expanded.value
  }

  function beforeExpand(elem) {
    elem.style.height = 0
    elem.style.opacity = 0
  }

  function expand(elem) {
    elem.style.height = `${elem.scrollHeight}px`
    elem.style.opacity = 1
  }

  function beforeCollapse(elem) {
    elem.style.height = `${elem.scrollHeight}px`
    elem.style.opacity = 1
  }

  function collapse(elem) {
    elem.style.height = 0
    elem.style.opacity = 0
  }
</script>
<style scoped>
  .expander-toggle {
    cursor: pointer;
  }
  .expander-content-wrapper {
    transition: 150ms ease-out;
  }
</style>
