import dayjs from "dayjs";

// Return true if obj == {}
export function isEmpty(obj: object) {
  // https://stackoverflow.com/a/32108184/697143
  for (const prop in obj) {
    if (Object.hasOwn(obj, prop)) {
      return false;
    }
  }
  return true;
}

/**
 * Replacement for Python's range function.
 * @param {int} start
 * @param {int} stop If not provided, then start is assumed to be 0 and stop is the start value.
 * @param {int} step [1]
 * @returns {Array}
 */
// From https://stackoverflow.com/a/8273091
export function range(start: number, stop?: number, step = 1) {
  if (typeof stop == "undefined") {
    // one param defined
    stop = start
    start = 0
  }

  if ((step > 0 && start >= stop) || (step < 0 && start <= stop)) {
    return []
  }

  const result = []
  for (let i = start; step > 0 ? i < stop : i > stop; i += step) {
    result.push(i)
  }

  return result
}

// Port of Python's sum function
export function sum(iterable: Array<number>) {
  return iterable.reduce((partialSum, a) => partialSum + a, 0)
}

/**
 * Validates that a given year, month, day add up to a valid date.
 * @param {int} y - Year.
 * @param {int} m - Month–zero-indexed!
 * @param {int} d - Day.
 * @returns {boolean}
 */
export function isValidDate(y: number, m: number, d: number) {
  const date = new Date(y, m, d)
  if (!dayjs(date).isValid()) {
    return false
  }
  return date.getFullYear() == y && date.getMonth() == m && date.getDate() == d
}

export function isOnline() {
  // This seems to be fairly useless, but is a placeholder until we have
  // something more robust.
  return navigator.onLine
}

// Given an array of objects with properties "value" & "label", return an object
// mapping value: label
export function convertDisplayList(displayList: Array<{ value: string, label: string }>) {
  const results = {}
  for (let i = 0; i < displayList.length; i++) {
    const { value, label } = displayList[i]
    results[value] = label
  }
  return results
}

export interface TaskQueuer {
  push: (task: () => Promise<void>, prepend?: boolean) => void
  emptyQueue: () => void
}

// From https://medium.com/@griffinmichl/asynchronous-javascript-queue-920828f6327
// Factory to return an object with an exposed prop "push" to add tasks to the queue.
// Specifically, each task passed in should be an async function that doesn't expect
// any arguments.
export function createTaskQueuer(concurrency = 3): TaskQueuer {
  let running = 0
  const taskQueue = []

  const cleanUp = () => {
    running--
    if (taskQueue.length > 0) {
      runTask(taskQueue.shift())
    }
  }

  const runTask = (task: () => Promise<void>) => {
    running++
    task().then(() => cleanUp())
  }

  const enqueueTask = (task: () => Promise<void>, prepend: boolean) => {
    if (prepend) {
      taskQueue.unshift(task)
    }
    else {
      taskQueue.push(task)
    }
  }

  return {
    push: (task: () => Promise<void>, prepend = false) => running < concurrency ? runTask(task) : enqueueTask(task, prepend),
    emptyQueue: () => taskQueue.length = 0
  }
}

/**
 * Inspired, warts and all, by https://stackoverflow.com/a/15724300
 * @param  {String} name  The name of the cookie
 * @return {String}       The cookie value
 */
export function getCookie(name: string) {
  const value = `; ${document.cookie}`
  const parts = value.split(`; ${name}=`)
  if (parts.length === 2) {
    return parts.pop().split(';').shift()
  }
}

export function isKnown(v: unknown): boolean {
  return v !== null && v !== undefined
}
