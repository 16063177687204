<script lang="ts" setup>
  import { ref, onUnmounted } from "vue"

  import PageTitleWithRefresh from "@/components/PageTitleWithRefresh.vue"
  import DownloadProjectContainer from "@/components/sites/DownloadProjectContainer.vue"
  import DownloadSiteContentWidget from "@/components/sites/DownloadSiteContentWidget.vue"
  import DownloadSiteVisitReportsWidget from "@/components/sites/DownloadSiteVisitReportsWidget.vue"
  import ExpandCollapse from "@/components/ExpandCollapse.vue"
  import LastSynced from "@/components/LastSynced.vue"
  import PageLoading from "@/components/PageLoading.vue"

  import {
    getLastUpdateInfo,
    areCachedDataStale,
    setCurrentViewContext,
   } from "@/utils/GlobalState"
  import { gettext } from "@/utils/Translation"
  import { createTaskQueuer } from "@/utils/Utilities"
  import {
    getCachedProjectsAndSites,
    updateSitesFromServer,
  } from "@/services/Site"
  import useServerRefresher from "@/composables/useServerRefresher"

  // We call for a low concurrency limit here bc our tasks actually involve
  // 2+ remote calls each, so we'll hit the browser limit of 6 concurrent calls
  // to the same domain too soon (once there are multiple modules enabled).
  const taskQueuer = createTaskQueuer(2)
  const { $gettext } = gettext

  const projects = ref(null)
  const sites = ref(null)
  const pageReady = ref(false)
  const lastSynced = ref(undefined)
  const { refreshUnderway, serverRefresher } = useServerRefresher()

  setCurrentViewContext()

  async function getSitesLocal() {
    if (await areCachedDataStale({ type: "sitesAndProjects" })) {
      await getSitesServer()
    }
    let projectsAndSites = await getCachedProjectsAndSites()
    projects.value = projectsAndSites.projects
    sites.value = projectsAndSites.sites
    if (!sites.value.length && !projects.value.length) {
      await getSitesServer()
      projectsAndSites = await getCachedProjectsAndSites()
      projects.value = projectsAndSites.projects
      sites.value = projectsAndSites.sites
    }
    pageReady.value = true
    lastSynced.value = await getLastUpdateInfo({ type: "sitesAndProjects" })
  }

  async function getSitesServer() {
    const results = await serverRefresher(updateSitesFromServer, [])
    lastSynced.value = await getLastUpdateInfo({ type: "sitesAndProjects" })
    return results
  }

  async function hardRefresh() {
    await getSitesServer()
    const projectsAndSites = await getCachedProjectsAndSites()
    projects.value = projectsAndSites.projects
    sites.value = projectsAndSites.sites
  }

  getSitesLocal()

  // If the user navigates away, empty the queue of any pending API requests. (Otherwise, they keep pounding the server to no end)
  onUnmounted(() => {
    taskQueuer.emptyQueue()
  })
</script>

<template>
  <article id="prepare-for-offline">
    <PageTitleWithRefresh
      :title="$gettext('Prepare for Offline Usage')"
      :refresh-function="hardRefresh"
      :refresh-underway="refreshUnderway"
    />
    <div class="message is-info">
      <p class="message-body">
        If you anticipate using <em>Count Me In</em> offline or with limited connectivity, download children and assessments below so you may access them without an internet connection.
        <router-link
          :to="{ name: 'DocumentationOfflineUsagePrepare'}"
          class="text-link"
        >
          Learn more.
        </router-link>
      </p>
    </div>
    <template v-if="pageReady">
      <template
        v-for="project in projects"
        :key="project.id"
      >
        <DownloadProjectContainer
          v-if="project.sites.length > 0"
          :project="project"
          :task-queuer="taskQueuer"
          :get-counts-preemptively="projects.length === 1"
          :expanded-at-start="projects.length === 1"
        />
      </template>
      <div
        v-if="sites.length"
        style="margin-top: 1em"
      >
        <ExpandCollapse :expanded-at-start="projects.length <= 1">
          <template #label>
            <h2 class="subtitle">
              {{ projects.length ? $gettext("Unclassified") : $gettext("Sites") }}
            </h2>
          </template>
          <template #content>
            <div
              v-for="site in sites"
              :key="site.id"
              class="block"
            >
              <strong>{{ site.name }}</strong>
              <DownloadSiteContentWidget
                :site="site"
                :task-queuer="taskQueuer"
                :get-counts="true"
              />
              <DownloadSiteContentWidget
                :site="site"
                :task-queuer="taskQueuer"
                :for-discharged-children="true"
                :get-counts="true"
              />
              <DownloadSiteVisitReportsWidget
                v-if="site.canCreateSiteVisitReports"
                :site="site"
                :task-queuer="taskQueuer"
                :get-counts="true"
              />
            </div>
          </template>
        </ExpandCollapse>
      </div>
      <p v-if="!sites.length && !projects.length">
        {{ $gettext("No sites are available to show.") }}
      </p>
    </template>
    <PageLoading
      v-else
      :with-text="true"
    />
    <LastSynced :last-synced="lastSynced" />
  </article>
</template>
