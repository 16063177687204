<template>
  <div class="columns is-mobile">
    <a
      v-if="downloadStatus === 'available'"
      class="column left-icon-col active"
      @click="initiateDownload()"
    >
      <IconDownload />
      {{ $gettext('Download') }}
    </a>
    <div
      v-else
      class="column left-icon-col disabled"
    >
      <template v-if="downloadStatus === 'disabled'">
        <IconDownload />
        {{ $gettext("Complete") }}
      </template>
      <template v-else-if="downloadStatus === 'downloading'">
        <IconSpinner />
        {{ $gettext("Downloading") }}
      </template>
      <template v-else-if="downloadStatus === 'complete'">
        <IconCheck />
        {{ $gettext("Complete") }}
      </template>
    </div>
    <div class="column data-col">
      <span class="item-label">
        {{ $gettext("Site visit reports: ") }}
      </span>
      <IconSpinner v-if="reportCount === null" />
      <span
        v-else
        class="muted"
      >
        {{ reportCount }}
      </span>
      <PieChartProgressIndicator
        v-if="downloadStatus === 'downloading' && reportCount"
        :percent-complete="downloadPercentComplete"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
  import { ref, watchEffect } from "vue"

  import {
    getPendingSiteVisitReportsForSite,
    processPaginatedSiteVisitReportsForSite,
  } from "@/services/Site"
  import { ISite } from "@/db"
  import { TaskQueuer } from "@/utils/Utilities"

  import IconSpinner from "@/components/svg/IconSpinner.vue"
  import PieChartProgressIndicator from "@/components/svg/PieChartProgressIndicator.vue"
  import IconDownload from "@/components/svg/IconDownload.vue"
  import IconCheck from "@/components/svg/IconCheckCircled.vue"

  interface Props {
    site: ISite,
    taskQueuer: TaskQueuer,
    getCounts: boolean,
  }

  const {
    site,
    taskQueuer,
    getCounts,
  } = defineProps<Props>()
  const reportCount = ref(null)
  const downloadStatus = ref("disabled")
  const downloadPercentComplete = ref(0)

  function initiateDownload() {
    downloadStatus.value = "downloading"
    taskQueuer.push(performDownload)
  }

  async function performDownload() {
    await processPaginatedSiteVisitReportsForSite(site, { percentComplete: downloadPercentComplete, total: reportCount.value })
    downloadStatus.value = "complete"
  }

  async function getData() {
    reportCount.value = await getPendingSiteVisitReportsForSite(site, { count: true })
    downloadStatus.value = reportCount.value ? "available" : "disabled"
  }

  watchEffect(() => {
      if (getCounts) {
        taskQueuer.push(getData)
      }
    })
</script>
